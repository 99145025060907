import api from '@/plugins/axios'

class ApiClient {
  constructor (modelName, isLanding) {
    this.modelName = modelName
    this.isLanding = isLanding
  }

  async getAll (params) {
    return await api.get(this.#urlWithId(), { params })
  }

  async getOne (id) {
    return await api.get(this.#urlWithId(id))
  }

  async create (formData) {
    return await api.post(this.#urlWithId(), formData)
  }

  async update (formData) {
    return await api.post(this.#urlWithId('update'), formData)
  }

  async delete (id) {
    return await api.delete(this.#urlWithId(id))
  }

  #urlWithId (params) {
    return params
      ? `/${this.isLanding ? 'landing' : 'admin'}/${this.modelName}/${params}`
      : `/${this.isLanding ? 'landing' : 'admin'}/${this.modelName}`
  }
}

export default ApiClient
