import ApiClient from '../api'
import { Category } from '../models'

const api = new ApiClient(Category.modelName)
const landingApi = new ApiClient(Category.modelName, true)

export const getCategories = async (params) => {
  const { data: categories } = await api.getAll(params)

  return categories
}

export const getCategory = async (id) => {
  const { data: category } = await api.getOne(id)

  return category
}

export const createCategory = async (formData) => {
  const { data: category } = await api.create(formData)

  return category
}

export const updateCategory = async (formData) => {
  const { data: category } = await api.update(formData)

  return category
}

export const removeCategory = async (id) => {
  return await api.delete(id)
}

export const getLandingCategories = async () => {
  const { data: categoriesResponse } = await landingApi.getAll()

  return categoriesResponse
}
