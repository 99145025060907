<template>
  <div>
    <div class="row">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-header">
            <div class="row">
              <div class="col-md-4 d-flex justify-content-between align-items-center">
                <div class="header-title me-5">
                  <h4 class="card-title">Редактировать</h4>
                </div>
              </div>
              <div class="col-md-4 offset-md-4 d-flex align-items-center">
                <div class="form-group input-group mb-0">
                  <input type="search" class="form-control" placeholder="Search...">
                  <span class="input-group-text" id="basic-addon1">
                    <svg width="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="11.7669" cy="11.7666" r="8.98856" stroke="currentColor" stroke-width="1.5"
                                stroke-linecap="round" stroke-linejoin="round"></circle>
                        <path d="M18.0186 18.4851L21.5426 22" stroke="currentColor" stroke-width="1.5"
                              stroke-linecap="round" stroke-linejoin="round"></path>
                    </svg>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div v-if="isLoading">Loading....</div>
            <Form v-else @submit="update" class="row mt-4" v-slot="{ meta }">
              <div class="col-md-6">
                <label for="name" class="form-label">Название</label>
                <Field v-model="shopData.name" name="name" class="form-control" label="Название" id="name"
                       rules="required" />
                <ErrorMessage name="name" class="text-danger" />

                <label for="country_id" class="form-label mt-4">Страна</label>
                <Field v-model="shopData.country.id" id="country_id" name="country_id" class="form-select"
                       as="select"
                       rules="required">
                  <option v-for="(country, index) in countries" :key="index" :value="country.id">
                    {{ country.name.ru }}
                  </option>
                </Field>
                <ErrorMessage class="text-danger" name="country_id"></ErrorMessage>

                <label for="category_id" class="form-label mt-4">Категория</label>
                <Field v-model="shopData.category.id" id="category_id" name="category_id" class="form-select"
                       as="select"
                       rules="required">
                  <option v-for="(category, index) in categories" :key="index" :value="category.id">
                    {{ category.name.ru }}
                  </option>
                </Field>
                <ErrorMessage class="text-danger" name="cagtegory_id" />

                <label for="country-photo" class="form-label mt-4">Логотип</label> <br>
                <button v-if="!hasShowFileInput" class="btn btn-primary" @click="hasShowFileInput = true">Изменить</button>
                <Field
                  v-else
                  id="country-photo"
                  type="file"
                  accept="image"
                  class="form-control"
                  rules="required"
                  name="image"
                  @change="onPickLogo"
                />
                <ErrorMessage class="text-danger" name="image" />
              </div>

              <div class="col-md-6">
                <label for="link" class="form-label">Ссылка</label>
                <Field v-model="shopData.link" id="link" type="text" class="form-control" name="link" rules="required" />
                <ErrorMessage class="text-danger" name="link"></ErrorMessage>

                <label for="link" class="form-label mt-4">Предпросмотр логотипа</label>
                <div
                  class="preview-logo"
                  :style="`
                    background-image: url('${logoPreview || shopData.image}');
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position: 50% 50%;
                    height: 100%;
                    max-height: 50%;
                  `"
                ></div>
              </div>

              <div class="text-center mt-4">
                <button :disabled="!(meta.valid && meta.dirty)" type="submit" class="btn btn-primary">Сохранить</button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useToast } from 'vue-toastification'
import { Form, Field, ErrorMessage } from 'vee-validate'
import { getShop, updateShop } from '@/services/shop.service'
import { getCountries } from '@/services/countries.service'
import { getCategories } from '@/services/categories.service'

const toast = useToast()

export default {
  name: 'ShopsEdit',
  data: () => ({
    logoPreview: null,
    shopData: null,
    countries: null,
    categories: null,
    isLoading: false,
    hasShowFileInput: false
  }),
  methods: {
    onPickLogo (e) {
      // eslint-disable-next-line no-unused-vars
      const [file, ...rest] = e.target.files
      this.logoPreview = URL.createObjectURL(file)
    },
    async fetchCategories () {
      const { data: categoriesResponse } = await getCategories()
      this.categories = categoriesResponse.data
    },
    async update (formValues) {
      try {
        const formData = new FormData()

        formData.append('store_id', this.$route.params.id)
        Object.entries(formValues).forEach(([name, value]) => {
          formData.append(name, value)
        })

        const response = await updateShop(formData)

        if (response.status === 'error') {
          toast.error('Error')
          return
        }

        await this.$router.push({ name: 'default.shops' })
      } catch (e) {
        Object.entries(e.response.data.error).forEach(([field, errorText]) => {
          toast.error(errorText[0])
        })
      }
    }
  },
  async created () {
    this.isLoading = true

    const { data: countries } = await getCountries()
    const { data: shop } = await getShop(this.$route.params.id)
    await this.fetchCategories()

    this.countries = countries.data
    this.shopData = shop

    this.isLoading = false
  },
  components: {
    Form,
    Field,
    ErrorMessage
  }
}
</script>
