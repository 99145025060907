import ApiClient from '../api'
import Country from '../models/Country'

const api = new ApiClient(Country.modelName)
const apiLanding = new ApiClient(Country.modelName, true)

export const getCountries = async (params) => {
  const { data: countries } = await api.getAll(params)

  return countries
}

export const getLandingCountries = async (params) => {
  const { data: countries } = await apiLanding.getAll(params)

  return countries
}

export const getCountry = async (id) => {
  const { data: country } = await api.getOne(id)

  return country
}

export const createCountry = async (formData) => {
  const { data: country } = await api.create(formData)

  return country
}

export const updateCountry = async (formData) => {
  const { data: country } = await api.update(formData)

  return country
}

export const removeCountry = async (id) => {
  return await api.delete(id)
}
