import ApiClient from '../api'
import { Shop } from '../models'
import axios from '@/plugins/axios'

const api = new ApiClient(Shop.modelName)

export const getShops = async (params) => {
  const { data: shops } = await api.getAll(params)

  return shops
}

export const getLandingShops = async (countryId) => {
  const { data: shops } = await axios.get(`/landing/countries/${countryId}/stores`)

  return shops
}

export const getShop = async (id) => {
  const { data: shop } = await api.getOne(id)

  return shop
}

export const createShop = async (formData) => {
  const { data: shop } = await api.create(formData)

  return shop
}

export const updateShop = async (formData) => {
  const { data: shop } = await api.update(formData)

  return shop
}

export const removeShop = async (id) => {
  return await api.delete(id)
}
